import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'



const prototypeDesign =()=>{
    return(
        <Layout>
            <SEO title="Prototype Design" />
            <div className="homeAuto">
                <Image alt="home automation" filename="bannerprotype.png" />
                <div className="electricalImage">
                    <h1 style={{fontWeight:"bold",fontSize:"50px"}} className="protohead">PROTOTYPE DESIGN</h1>
                    <h3 className="crmheadpara">Bringing ideas into reality</h3>
                    <p className="electriP" style={{fontSize:"20px"}}>Our experts in prototype design can help you take your<br/>
                                                next great idea new heights!</p>
                </div>
            </div>

            <Container >
                <Row className="protoRow">
                    <Col sm={12} lg={6} style={{paddingRight:"50px"}}><Image alt="prototype" filename="prototype.jpg"  /></Col>
                    <Col sm={12} lg={6} className="manuPara1">
                    <p>Trace Width Technology Solutions is an innovative prototype design company. Our services extend from Industrial Design to Product Development, Engineering and manufacturing. We can provide complete prototypes for inventors and companies in various industries. Our strong focus on the final prototype helps us to prepare prototypes that are a final copy of the final product that will be sold and marketed.</p>
                    <p>Our goal is it to provide you with full service prototype development and help you design the final product with ease. The provided prototype will serve as an important tool to test the functionality and also help you describe your product more effectively before delivering the final product. We adhere to using state of the art product development equipment and this in turn enables us to achieve impressive prototype manufacturing and design results from start to finish.</p>
                </Col>
                </Row>
                <p className="protoPara">Your ideas have the potential in making millions. There’s only one obstacle you must overcome.The development of a fully functional prototype.</p>
                <Row className="ProtoRow1">
                    <Col sm={12} lg={4}><Image alt="proto2" filename="proto12.png" /></Col>
                    <Col sm={12} lg={4}><Image alt="proto3" filename="proto3-1.png" /></Col>
                    <Col sm={12} lg={4}><Image alt="proto4" filename="proto4.png"  /></Col>
                </Row>
            </Container>
            <div className="protoForm">
                <Container >
                    <Row >
                        <Col sm={6} className="manuPara1" style={{marginTop:"40px"}}>
                            <p>One of the outstanding ways to disseminate your ideas and assess your product is by generating high quality, accurate 3D prototypes. Our exhaustive, rapid prototyping services can trim the time it takes to work through design recurrence and drill down on any assembly issues. Rapid Prototyping reduces lead times and helps control the overall cost of your product development. Here at Trace Width Technology Solutions, we ensure you are utilizing the correct processes and prototyping applications for your product. We provide rapid prototyping services, additive manufacturing and subtractive rapid prototyping services. See how your designs and Ideas are altered to a new level with our full suite of rapid prototyping services.</p>
                        </Col>
                        <Col sm={2} className="protoCol"><Image alt="prototype1" filename="pro1.png" /></Col>
                        <Col sm={2} className="protoCol"><Image alt="prototype2" filename="pro2.png" /></Col>
                        <Col sm={2} className="protoCol"><Image alt="prototype3" filename="pro3.png" /></Col>
                    </Row>
                </Container>
            </div>

            <div className="protoForm1"> 
            <br/>
            <div className="homeCardrow5">Ready to get your design off the ground? Contact Trace Width Technology Solutions today!</div>
            <ContactForm  />
        </div>
        </Layout>
    )
}

export default prototypeDesign;